import { ScrollToTop } from "react-router-scroll-to-top";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import { LoaderPage } from "../components/loading/LoaderPage";
import ComplaintsPage from "../views/Complaints/ComplaintsPage";
import NoticesPage from "../views/Notices/NoticesPage";
import NoticesPageSlug from "../views/Notices/NoticesPageSlug";
import { getCurrentDate } from "../utils/getCurrentDate";
import { DefaultLayout } from "./layouts/Default";

const HomePage = lazy(() => import("../views/Home/HomePage"));

/* ========== For You (Para Você) ========== */
const LoansPage = lazy(() => import("../views/ForYou/Loans/LoansPage"));
const LoansFGTSPage = lazy(
  () => import("../views/ForYou/LoansFGTS/LoansFGTSPage")
);
const InvestmentsPage = lazy(
  () => import("../views/ForYou/Investments/InvestmentsPage")
);
const CreditCardsPage = lazy(
  () => import("../views/ForYou/CreditCards/CreditCardsPage")
);
const CurrentAccountPageParaVoce = lazy(
  () => import("../views/ForYou/CurrentAccount/CurrentAccountPage")
);

const InsuranceAssistanceBenefitsPage = lazy(
  () => import("../views/ForYou/InsuranceAssistanceBenefits/InsuranceAssistanceBenefitsPage")
)

/* ========== For Your Business (Para sua Empresa) ========== */
const LeasingPage = lazy(
  () => import("../views/ForYourBusiness/Leasing/LeasingPage")
);
const ForeignCommerce = lazy(
  () => import("../views/ForYourBusiness/ForeignCommerce/ForeignCommercePage")
);
const PrivateCreditPage = lazy(
  () => import("../views/ForYourBusiness/PrivateCredit/PrivateCreditPage")
);
const PrivateCreditPageParaVoce = lazy(
  () =>
    import("../views/ForYourBusiness/PrivateCredit/PrivateCreditPageParaVoce")
);

const CurrentAccountPage = lazy(
  () => import("../views/ForYourBusiness/CurrentAccount/CurrentAccountPage")
);
const ForYourBusinessPage = lazy(
  () => import("../views/ForYourBusiness/ForYourBusiness/ForYourBusinessPage")
);
const LoansAndFinancingPage = lazy(
  () =>
    import("../views/ForYourBusiness/LoansAndFinancing/LoansAndFinancingPage")
);
const InvestmentsPageBusiness = lazy(
  () => import("../views/ForYourBusiness/Investments/InvestmentsPageBussines")
);

/* ========== About Us (Sobre nós) ========== */
const AboutUsPage = lazy(
  () => import("../views/AboutUsPages/AboutUs/AboutUsPage")
);
const AuthorizedCorrespondents = lazy(
  () =>
    import(
      "../views/AboutUsPages/AuthorizedCorrespondents/AuthorizedCorrespondents"
    )
);
const RIPage = lazy(() => import("../views/AboutUsPages/AboutUs/RIPage"));
const GovernancePage = lazy(() => import("../views/Governance/GovernancePage"));

/* ========== Commercial Services (Atendimento) ========== */
const ContactPage = lazy(() => import("../views/Contact/ContactPage"));
const ContactPageSaibaMaisParaVoce = lazy(
  () => import("../views/Contact/ContactPageSaibaMaisParaVoce")
);
const ContactPageSaibaMaisParaSuaEmpresa = lazy(
  () => import("../views/Contact/ContactPageSaibaMaisParaSuaEmpresa")
);
/* ========== Card Request Form ==========  */
const FormCardBenefits = lazy(
  () => import("../views/FormCard/FormCardBenefits/FormCardBenefits")
);
const FormCardRequestCard = lazy(
  () => import("../views/FormCard/FormCardRequest/FormCardRequestPage")
);
const FormStepOne = lazy(
  () => import("../views/FormCard/FormStepOne/FormStepOne")
);
const FormStepTwo = lazy(
  () => import("../views/FormCard/FormStepTwo/FormStepTwo")
);
const FormStepThree = lazy(
  () => import("../views/FormCard/FormStepThree/FormStepThree")
);
const FormStepFour = lazy(
  () => import("../views/FormCard/FormStepFour/FormStepFour")
);
const FormStepFive = lazy(
  () => import("../views/FormCard/FormStepFive/FormStepFive")
);
const FormCardCompleted = lazy(
  () => import("../views/FormCard/FormCardCompleted/FormCardCompletedPage")
);

/* ========== Miscellaneous ========== */
const Test = lazy(() => import("../views/Test"));
const AlertPage = lazy(() => import("../views/AlertPage/AlertPage"));
const ListCardPage = lazy(() => import("../views/ListCard/ListCardPage"));
const ListCardPageNBC = lazy(
  () => import("../views/ListCardNBC/ListCardPageNBC")
);
const ListCardPageParceiros = lazy(
  () => import("../views/ListCardParceiros/ListCardPageParceiros")
);
const FraudAlertPage = lazy(() => import("../views/FraudAlert/FraudAlertPage"));
const WorkWithUsPage = lazy(() => import("../views/WorkWithUS/WorkWithUsPage"));
const WorkWithUsVagaPage = lazy(
  () => import("../views/WorkWithUS/WordWithUsVagaPage")
);

const AccountingStatementPage = lazy(
  () => import("../views/AccountingStatement/AccountingStatementPage")
);

/* ============= Atendimento =============*/
const OmbudsmanPage = lazy(async () => {
  const [module, data] = await Promise.all([
    import("../views/Ombudsman/OmbudsmanPage"),
    getCurrentDate()
  ])

  const Component = module.default

  return {
    default: () => <Component now={data} />
  }
})
const DataSubjectsPage = lazy(
  () => import("../views/DataSubjects/DataSubjectsPage")
);

/* ========== AbraContaPJ ========== */
const CompanyIdentification = lazy(
  () => import("../views/AbraContaPJ/CompanyIdentification")
);
const ComplementaryDataPJ = lazy(
  () => import("../views/AbraContaPJ/ComplementaryData")
);
const AttachDocuments = lazy(
  () => import("../views/AbraContaPJ/AttachDocuments")
);
const FinishRequestReviewData = lazy(
  () => import("../views/AbraContaPJ/FinishRequestReviewData")
);
const FinishRequestTermsConditions = lazy(
  () => import("../views/AbraContaPJ/FinishRequestTermsConditions")
);
const FinishRequestFinished = lazy(
  () => import("../views/AbraContaPJ/FinishRequestFinished")
);

/* ========== AbraContaPF ========== */
const PersonalData = lazy(() => import("../views/AbraContaPF/PersonalData"));
const Endereco = lazy(() => import("../views/AbraContaPF/Endereco"));
const ComplementaryDataPF = lazy(
  () => import("../views/AbraContaPF/ComplementaryData")
);
const FinishRequestReviewDataPF = lazy(
  () => import("../views/AbraContaPF/FinishRequestReviewDataPF")
);
const FinishRequestTermsConditionsPF = lazy(
  () => import("../views/AbraContaPF/FinishRequestTermsConditionsPF")
);
const FinishRequestFinishedPF = lazy(
  () => import("../views/AbraContaPF/FinishRequestFinishedPF")
);
const AttachDocumentsPF = lazy(
  () => import("../views/AbraContaPF/AttachDocumentsPF")
);
const AgenciesPage = lazy(() => import("../views/AgenciesPages/AgenciesPage"));
const RiskManagementPage = lazy(
  () => import("../views/RiskManagement/RiskManagementPage")
);
const ESGPage = lazy(() => import("../views/ESG/ESGPage"));
const FAQPage = lazy(() => import("../views/FAQ/FAQPage"));
const FAQCategoryPage = lazy(
  () => import("../views/FAQ/FAQCategories/FAQCategoryPage")
);
const UnsuscriptionPage = lazy(
  () => import("../views/Unsuscription/UnsuscriptionPage")
);
const UnsuscriptionPageSuccess = lazy(
  () => import("../views/Unsuscription/UnsuscriptionPageSuccess")
);

const CotacaoDolarPage = lazy(async () => {
  const [module, data] = await Promise.all([
    import("../views/CotacaoDolar/CotacaoDolarPage"),
    getCurrentDate()
  ])

  const Component = module.default

  return {
    default: () => <Component now={data} />
  }
})

const AppRoutes = () => {
  return (
    <Suspense fallback={<LoaderPage />}>
      <BrowserRouter>
        <ScrollToTop />
        {/* Component to scroll to the top of the page when we get to a new page */}
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/cotacao-dolar" element={<CotacaoDolarPage />} />
            <Route path="/para-voce">
              <Route
                path="saiba-mais/contato"
                element={<ContactPageSaibaMaisParaVoce />}
              />
              <Route
                path="investimentos/:id_page"
                element={<InvestmentsPage />}
              />
              <Route
                path="emprestimo-consignado/:id_page"
                element={<LoansPage />}
              />
              <Route
                path="credito-consignado/:id_page"
                element={<PrivateCreditPageParaVoce />}
              />
              <Route
                path="emprestimos-fgts/:id_page"
                element={<LoansFGTSPage />}
              />
              <Route path="cartoes/:id_page" element={<CreditCardsPage />} />
              <Route
                path="conta-corrente/:id_page"
                element={<CurrentAccountPageParaVoce />}
              />
              {/* <Route path="seguros/:id_page" element={<InsuranceAssistanceBenefitsPage />} /> */}
              <Route path="*" element={<HomePage />} />
            </Route>
            <Route path="/para-sua-empresa">
              <Route
                path="saiba-mais/contato"
                element={<ContactPageSaibaMaisParaSuaEmpresa />}
              />
              <Route
                path="emprestimos-financiamentos/:id_page"
                element={<LoansAndFinancingPage />}
              />
              <Route
                path="comercio-exterior/:id_page"
                element={<ForeignCommerce />}
              />
              <Route path="leasing/:id_page" element={<LeasingPage />} />
              <Route
                path="credito-consignado/:id_page"
                element={<PrivateCreditPage />}
              />
              <Route
                path="investimentos/:id_page"
                element={<InvestmentsPageBusiness />}
              />
              <Route
                path="conta-corrente/:id_page"
                element={<CurrentAccountPage />}
              />
              <Route
                path="sua-empresa/:id_page"
                element={<ForYourBusinessPage />}
              />
              <Route path="*" element={<HomePage />} />
            </Route>
            <Route path="/sobre-nos">
              <Route path="quem-somos/:id_page" element={<AboutUsPage />} />
              <Route
                path="quem-somos/:id_page/:id_marker"
                element={<AboutUsPage />}
              />
              <Route path="agencias/:id_page" element={<AgenciesPage />} />
              <Route
                path="correspondentes/:id_page"
                element={<AuthorizedCorrespondents />}
              />
              <Route path="ri/:id_page" element={<RIPage />} />
              <Route path="governanca/:id_page" element={<GovernancePage />} />
            </Route>
            <Route path="/test" element={<Test />} />
            <Route
              path="/gerenciamento-riscos"
              element={<RiskManagementPage />}
            />
            <Route
              path="/demonstracoes-contabeis"
              element={<AccountingStatementPage />}
            />
            <Route path="/esg" element={<ESGPage />} />
            <Route path="/alerta-page" element={<AlertPage />} />
            {/* Routes for forms */}
            <Route path="/contato/:id_page" element={<ContactPage />} />
            <Route path="/ouvidoria/:id_page" element={<OmbudsmanPage />} />
            <Route path="/denuncias/:id_page" element={<ComplaintsPage />} />
            <Route path="/titulares/:id_page" element={<DataSubjectsPage />} />
            <Route path="/contato" element={<ContactPage />} />
            <Route path="/list-cartao" element={<ListCardPage />} />
            <Route path="/alerta-de-fraude" element={<FraudAlertPage />} />

            {/* Trabalhe conosco */}
            <Route path="/trabalhe-conosco" element={<WorkWithUsPage />} />
            <Route
              path="/trabalhe-conosco/vaga"
              element={<WorkWithUsVagaPage />}
            />
            <Route
              path="/trabalhe-conosco/vaga/:work_name"
              element={<WorkWithUsVagaPage />}
            />

            <Route path="/list-cartao/nbcbank" element={<ListCardPageNBC />} />
            <Route
              path="/list-cartao/parceiros"
              element={<ListCardPageParceiros />}
            />
            <Route path="/abra-conta-pj">
              <Route
                path="identificacao-empresa"
                element={<CompanyIdentification />}
              />
              <Route
                path="dados-complementares"
                element={<ComplementaryDataPJ />}
              />
              <Route path="anexar-documentos" element={<AttachDocuments />} />
              <Route
                path="finalizar-cadastro"
                element={<FinishRequestReviewData />}
              />
              <Route
                path="finalizar-cadastro/termos-condicoes"
                element={<FinishRequestTermsConditions />}
              />
              <Route
                path="finalizar-cadastro/finalizado"
                element={<FinishRequestFinished />}
              />
            </Route>
            <Route path="/abra-conta-pf">
              <Route path="dados-pessoais" element={<PersonalData />} />
              <Route path="endereco" element={<Endereco />} />
              <Route path="anexar-documentos" element={<AttachDocumentsPF />} />
              <Route
                path="dados-complementares"
                element={<ComplementaryDataPF />}
              />
              <Route
                path="finalizar-cadastro"
                element={<FinishRequestReviewDataPF />}
              />
              <Route
                path="finalizar-cadastro/termos-condicoes"
                element={<FinishRequestTermsConditionsPF />}
              />
              <Route
                path="finalizar-cadastro/finalizado"
                element={<FinishRequestFinishedPF />}
              />
            </Route>
            <Route path="*" element={<HomePage />} />
            <Route path="/FAQ/:id_page" element={<FAQPage />} />
            <Route
              path="/FAQ/:id_page/:id_categoria"
              element={<FAQCategoryPage />}
            />
            <Route
              path="/cancelamento-email/:email"
              element={<UnsuscriptionPage />}
            />
            <Route path="/cancelamento-email" element={<UnsuscriptionPage />} />
            <Route
              path="/cancelamento-sucesso"
              element={<UnsuscriptionPageSuccess />}
            />
            <Route path="/noticias/:id_page" element={<NoticesPage />} />
            <Route
              path="/noticias/:id_page/:id_notice"
              element={<NoticesPageSlug />}
            />
          </Route>
          <Route path="/cartao">
            {/* With ID and HASH CODE only */}
            <Route
              path=":id/beneficios"
              element={<FormCardBenefits />}
            />
            <Route
              path=":id/:hashProposta"
              element={<FormCardRequestCard />}
            />
            <Route
              path=":id/:hashProposta/dados-pessoais"
              element={<FormStepOne />}
            />
            <Route
              path=":id/:hashProposta/dados-adicionais"
              element={<FormStepTwo />}
            />
            <Route
              path=":id/:hashProposta/dados-profissionais"
              element={<FormStepThree />}
            />
            <Route
              path=":id/:hashProposta/endereco"
              element={<FormStepFour />}
            />
            <Route
              path=":id/:hashProposta/personalizacao"
              element={<FormStepFive />}
            />
            <Route
              path=":id/:hashProposta/pedido-recebido"
              element={<FormCardCompleted />}
            />
          </Route>
          <Route path="/cartoes">
            <Route
              path=":parceiro/beneficios/:id"
              element={<FormCardBenefits />}
            />
            <Route
              path=":parceiro/pedir-cartao/:id"
              element={<FormCardRequestCard />}
            />
            <Route
              path=":parceiro/dados-pessoais/:id"
              element={<FormStepOne />}
            />
            <Route
              path=":parceiro/dados-adicionais/:id"
              element={<FormStepTwo />}
            />
            <Route
              path=":parceiro/dados-profissionais/:id"
              element={<FormStepThree />}
            />
            <Route path=":parceiro/endereco/:id" element={<FormStepFour />} />
            <Route
              path=":parceiro/personalizacao/:id"
              element={<FormStepFive />}
            />
            <Route
              path=":parceiro/pedido-recebido/:id"
              element={<FormCardCompleted />}
            />
            {/* With CNPJ */}

            <Route
              path=":parceiro/beneficios/:id/:cnpj"
              element={<FormCardBenefits />}
            />
            <Route
              path=":parceiro/pedir-cartao/:id/:cnpj"
              element={<FormCardRequestCard />}
            />
            <Route
              path=":parceiro/dados-pessoais/:id/:cnpj"
              element={<FormStepOne />}
            />
            <Route
              path=":parceiro/dados-adicionais/:id/:cnpj"
              element={<FormStepTwo />}
            />
            <Route
              path=":parceiro/dados-profissionais/:id/:cnpj"
              element={<FormStepThree />}
            />
            <Route
              path=":parceiro/endereco/:id/:cnpj"
              element={<FormStepFour />}
            />
            <Route
              path=":parceiro/personalizacao/:id/:cnpj"
              element={<FormStepFive />}
            />
            <Route
              path=":parceiro/pedido-recebido/:id/:cnpj"
              element={<FormCardCompleted />}
            />

            {/* With CNPJ and Base 64*/}
            <Route
              path=":parceiro/beneficios/:id/:cnpj/:hashProposta"
              element={<FormCardBenefits />}
            />
            <Route
              path=":parceiro/pedir-cartao/:id/:cnpj/:hashProposta"
              element={<FormCardRequestCard />}
            />
            <Route
              path=":parceiro/dados-pessoais/:id/:cnpj/:hashProposta"
              element={<FormStepOne />}
            />
            <Route
              path=":parceiro/dados-adicionais/:id/:cnpj/:hashProposta"
              element={<FormStepTwo />}
            />
            <Route
              path=":parceiro/dados-profissionais/:id/:cnpj/:hashProposta"
              element={<FormStepThree />}
            />
            <Route
              path=":parceiro/endereco/:id/:cnpj/:hashProposta"
              element={<FormStepFour />}
            />
            <Route
              path=":parceiro/personalizacao/:id/:cnpj/:hashProposta"
              element={<FormStepFive />}
            />
            <Route
              path=":parceiro/pedido-recebido/:id/:cnpj/:hashProposta"
              element={<FormCardCompleted />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutes;
